import * as React from "react";

import { ContentWrapper } from "../components/content-wrapper";

import {
  RichtextHeadingABorderLeft,
  RichtextHeadingABorderLeftNarrow,
  RichtextHeadingABorderRight,
} from "@topm/shared";

import { Layout } from "../components/layout";

import { Label } from "@topm/shared";
import { PageProps } from "gatsby";

const ComponentsPage: React.FC<PageProps> = () => {
  return (
    <Layout>
      <ContentWrapper className="pb-vgap-lg">
        <div className="h-[50px]"></div>
        <Label>RichtextHeadingABorderLeft</Label>
        <RichtextHeadingABorderLeft>hoge</RichtextHeadingABorderLeft>
        <Label>RichtextHeadingABorderLeftNarrow</Label>
        <RichtextHeadingABorderLeftNarrow>
          hoge
        </RichtextHeadingABorderLeftNarrow>
        <Label>RichtextHeadingABorderRight</Label>
        <RichtextHeadingABorderRight>hoge</RichtextHeadingABorderRight>
      </ContentWrapper>
      <div className="h-[30vh]"></div>
    </Layout>
  );
};

export default ComponentsPage;
